import { ApolloClient } from "@apollo/client";
import { optimisticUpdateAndServerRequestWithUndo } from "./api.utils";
import { requestAddMemberToCommunity, requestCreateCommunity } from "frontend-shared/generated/graphqlWrappers";
import { addNewCommunity, AdminCommunitiesState, removeCommunity } from "frontend-shared/store/adminCommunitiesSlice";

import { uuidForId } from "../util/uuidUtils";
import { AppDispatch } from "../store/store";

export interface AdminCommunityOptimisticAPIContext {
  client: ApolloClient<object>;
  adminCommunitiesState: AdminCommunitiesState;
  dispatch: AppDispatch;
}

export const apiAddCommunity = (
  apiContext: AdminCommunityOptimisticAPIContext,
  name: string,
  onSuccess: () => void,
  onError: (err: string) => void
) => {
  const { client, dispatch } = apiContext;
  const communityId = uuidForId();

  optimisticUpdateAndServerRequestWithUndo({
    client,
    dispatch,
    optimisticUpdate: addNewCommunity({ communityId, name }),
    request: requestCreateCommunity,
    variables: { communityId, name },
    undo: removeCommunity({ communityId }),
  });

  // we always end on success because errors are handled by the optimistic update
  onSuccess();
};

export const apiAddMemberToCommunity = (
  apiContext: AdminCommunityOptimisticAPIContext,
  communityId: string,
  email: string,
  isAdmin: boolean,
  onSuccess: () => void,
  onError: (msg: string) => void
) => {
  const { client, dispatch } = apiContext;

  // There is no need to do an optimistic update because we don't keep track of the members.
  requestAddMemberToCommunity(client, { communityId, email, isAdmin }, onSuccess, onError);
};
