import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AdminCommunitiesQuery, CommunitiesScreenQuery } from "../generated/graphql";
import { RootState } from "./store";
import { CommunityForAdminScreen } from "../util/modelTypes";

export interface AdminCommunitiesState {
  initialized: boolean;
  errorMessage?: string;
  communities: CommunityForAdminScreen[];
}

const initialState: AdminCommunitiesState = {
  initialized: false,
  errorMessage: undefined,
  communities: [],
};

export const adminCommunitiesSlice = createSlice({
  name: "adminCommunities",
  initialState,
  reducers: {
    clearCommunitiesState: (state) => {
      state.initialized = false;
      state.communities = initialState.communities;
      state.errorMessage = initialState.errorMessage;
    },
    initializeCommunitiesState: (state, action: PayloadAction<{ data: AdminCommunitiesQuery }>) => {
      const { data } = action.payload;
      state.initialized = true;
      state.communities = data.adminCommunities.map((community) => ({
        id: community.id,
        name: community.name,
      }));
      state.errorMessage = initialState.errorMessage;
    },
    addNewCommunity: (
      state,
      action: PayloadAction<{
        communityId: string;
        name: string;
      }>
    ) => {
      state.communities.push({
        id: action.payload.communityId,
        name: action.payload.name,
      });
    },
    removeCommunity: (state, action: PayloadAction<{ communityId: string }>) => {
      state.communities = state.communities.filter((community) => community.id !== action.payload.communityId);
    },
    setCommunitiesErrorMessage: (state, action: PayloadAction<string>) => {
      state.initialized = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { clearCommunitiesState, initializeCommunitiesState, addNewCommunity, removeCommunity, setCommunitiesErrorMessage } =
  adminCommunitiesSlice.actions;

export const selectAdminCommunitiesState = (state: RootState) => state.adminCommunities as AdminCommunitiesState;

export default adminCommunitiesSlice.reducer;
