import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { LoginPage } from "./userApp/loginPage";
import { ChangePasswordPage } from "./userApp/changePasswordPage";
import { InvitePage } from "./userApp/invitePage";

import { TodayPage } from "./userApp/todayPage";
import { InviteUserPage } from "./adminApp/inviteUserPage";
import { AdminStatsPage } from "./adminApp/adminStatsPage";
import { InvitationsPage } from "./adminApp/invitationsPage";
import { FeatureFlagPage } from "./userApp/featureFlagPage";
import { AdminCommunitiesPage } from "./adminApp/adminCommunitiesPage";

export const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="change-password" element={<ChangePasswordPage />} />

        <Route path="/" element={<TodayPage />} />
        <Route path="/date/:dateParam" element={<TodayPage />} />
        <Route path="invite/:inviteCode" element={<InvitePage />} />

        <Route path="feature-flag/:flag" element={<FeatureFlagPage />} />

        {/* Site admin routes */}
        <Route path="admin/invite-user" element={<InviteUserPage />} />
        <Route path="admin/invitations" element={<InvitationsPage />} />
        <Route path="admin/stats" element={<AdminStatsPage />} />
        <Route path="admin/communities" element={<AdminCommunitiesPage />} />
      </Routes>
    </BrowserRouter>
  );
};
