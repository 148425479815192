import React, { useState } from "react";
import { ObjectArrayTable, TableObject } from "../../../components/objectArrayTable/objectArrayTable";
import { AdminCommunitiesQuery } from "frontend-shared/generated/graphql";
import { SortColumnDescriptor } from "../../../components/objectArrayTable/objectArrayTableUtils";
import { Button, Typography } from "@mui/material";
import { PromptForTextDialog } from "src/components/dialogs/promptForTextDialog";

export type Community = AdminCommunitiesQuery["adminCommunities"][0];

interface Props {
  data: Community[];
  dataDescription: string;
  addMemberToCommunity: (communityId: string, email: string, isAdmin: boolean, onSuccess: () => void, onError: (msg: string) => void) => void;
}

export const CommunitiesTable: React.FC<Props> = ({ data, dataDescription, addMemberToCommunity }) => {
  const [sortColumn, setSortColumn] = useState<SortColumnDescriptor>({ index: 0, ascending: true });
  const [promptForEmailIsOpen, setPromptForEmailIsOpen] = useState(false);
  const [communityId, setCommunityId] = useState<string | null>(null);

  const sortedData = [...data];
  const sortCommunitiesFn = (a: Community, b: Community) => {
    const reverse = sortColumn.ascending ? 1 : -1;
    return a.name.localeCompare(b.name) * reverse;
  };
  sortedData.sort(sortCommunitiesFn);

  return (
    <>
      <ObjectArrayTable
        dataDescription={dataDescription}
        title={["Name", "Members"]}
        data={sortedData}
        colWidths={[75, 25]}
        cellNode={function (obj: Community, index: number, array: TableObject[]): React.ReactNode | React.ReactNode[] {
          return [
            <Typography>{obj.name}</Typography>,
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCommunityId(obj.id);
                setPromptForEmailIsOpen(true);
              }}
            >
              Add Member
            </Button>,
          ];
        }}
        onClick={function (id: string): void {}}
        headerSort={sortColumn}
        onHeaderClick={(colIndex) => {
          setSortColumn({
            index: colIndex,
            ascending: sortColumn.index === colIndex ? !sortColumn.ascending : true,
          });
        }}
      />
      <PromptForTextDialog
        open={promptForEmailIsOpen}
        onClose={() => setPromptForEmailIsOpen(false)}
        label="Email"
        buttonLabel="Add Member"
        onSave={async (text, onSuccess, onError) => {
          if (communityId) {
            addMemberToCommunity(communityId, text, true, onSuccess, onError);
          } else {
            onError(`Cannot add ${text} to community`);
          }
        }}
      />
    </>
  );
};
