import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useApolloClient } from "@apollo/client";
import { AppLayout } from "src/components/layouts/appLayout";
import { PaperWithTitle } from "src/components/paperWithTitle";
import { AppErrorPage } from "src/components/appErrorPage";
import { AppLoadingPage } from "src/components/appLoadingPage";
import { CommunitiesTable } from "./communities/communitiesTable";
import { PromptForTextDialog } from "src/components/dialogs/promptForTextDialog";
import { useAdminCommunitiesQuery } from "frontend-shared/generated/graphql";
import { AdminCommunityOptimisticAPIContext, apiAddCommunity, apiAddMemberToCommunity } from "frontend-shared/api/adminCommunities.api";
import { useAppDispatch, useAppSelector } from "frontend-shared/store/hooks";
import { selectAdminCommunitiesState, initializeCommunitiesState, clearCommunitiesState } from "frontend-shared/store/adminCommunitiesSlice";
import { showSnackbarSuccess } from "frontend-shared/store/appSnackbarSlice";
import { AppSnackbar } from "src/components/appSnackbar";

interface Props {}

export const AdminCommunitiesPage: React.FC<Props> = () => {
  const [promptForCommunityNameIsOpen, setPromptForCommunityNameIsOpen] = useState(false);
  const { data, loading, error } = useAdminCommunitiesQuery({ fetchPolicy: "network-only" });

  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const adminCommunitiesState = useAppSelector(selectAdminCommunitiesState);

  // You need an effect to deal with loading state because you want to change state
  // via a dispatch while loading. You can't do that in the render function.
  useEffect(() => {
    if (loading) {
      dispatch(clearCommunitiesState());
    } else if (data && !adminCommunitiesState.initialized) {
      dispatch(initializeCommunitiesState({ data }));
    }
  });

  if (error) {
    return <AppErrorPage errorMessage={error.message} />;
  }
  if (loading || !adminCommunitiesState.initialized) {
    return <AppLoadingPage />;
  }
  if (!data) {
    return <AppErrorPage errorMessage="No data received" />;
  }

  const ctx: AdminCommunityOptimisticAPIContext = { client, adminCommunitiesState, dispatch };

  return (
    <AppLayout chosen="Communities">
      <PaperWithTitle title="Communities">
        <Stack>
          <Typography>Communities</Typography>
          <CommunitiesTable
            data={adminCommunitiesState.communities}
            dataDescription="Communities"
            addMemberToCommunity={(communityId, email, isAdmin, onSuccess, onError) => {
              apiAddMemberToCommunity(
                ctx,
                communityId,
                email,
                isAdmin,
                () => {
                  dispatch(showSnackbarSuccess("Member added to community"));
                  onSuccess();
                },
                onError,
              );
            }}
          />
          <Button variant="contained" sx={{ alignSelf: "flex-end", mt: 2 }} onClick={() => setPromptForCommunityNameIsOpen(true)}>
            Add Community
          </Button>
        </Stack>
        <PromptForTextDialog
          open={promptForCommunityNameIsOpen}
          onClose={() => {
            setPromptForCommunityNameIsOpen(false);
          }}
          label="Community Name"
          buttonLabel="Create Community"
          onSave={async (text: string, onSuccess: () => void, onError: (msg: string) => void) => {
            apiAddCommunity(ctx, text, onSuccess, onError);
          }}
          defaultText={undefined}
        />
      </PaperWithTitle>
      <AppSnackbar />
    </AppLayout>
  );
};
