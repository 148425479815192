import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { CommunityForScreen, CommunityFullForScreen } from "../util/modelTypes";
import { CommunitiesScreenFullQuery, CommunitiesScreenQuery } from "../generated/graphql";

export interface UserCommunitiesState {
  initialized: boolean;
  errorMessage?: string;
  communities: CommunityForScreen[];
  communitiesFull: CommunityFullForScreen[];
}

const initialState: UserCommunitiesState = {
  initialized: false,
  errorMessage: undefined,
  communities: [],
  communitiesFull: [],
};

export const userCommunitiesSlice = createSlice({
  name: "userCommunities",
  initialState,
  reducers: {
    clearUserCommunitiesState: (state) => {
      state.initialized = false;
      state.communities = initialState.communities;
      state.errorMessage = initialState.errorMessage;
      state.communitiesFull = initialState.communitiesFull;
    },
    initializeUserCommunitiesState: (state, action: PayloadAction<{ data: CommunitiesScreenQuery }>) => {
      const { data } = action.payload;
      state.initialized = true;
      state.communities = data.userAccount.communities.map((community) => ({
        id: community.id,
        name: community.name,
      }));
      state.errorMessage = initialState.errorMessage;
    },
    setCommunitiesErrorMessage: (state, action: PayloadAction<string>) => {
      state.initialized = false;
      state.errorMessage = action.payload;
    },
    initializeUserCommunitiesFullState: (state, action: PayloadAction<{ data: CommunitiesScreenFullQuery }>) => {
      const { data } = action.payload;

      state.communitiesFull = data.userAccount.communities.map((community) => ({
        id: community.id,
        name: community.name,
        sharedObjects: community.sharedObjects.map((sharedObject) => {
          const sharedObjectData = JSON.parse(sharedObject.sharedObjectDataJSON ?? "{}");
          return {
            id: sharedObject.id,
            name: sharedObjectData.name ?? "",
            userName: sharedObject.userName,
            shareType: sharedObject.shareType,
            selfReactions: sharedObjectData.selfReactions ?? [],
            createdDate: sharedObject.createdDate,
            reactionSummary: sharedObject.reactionSummary.map((reaction) => ({
              type: reaction.type,
              count: reaction.count,
            })),
            sharedObjectDataJSON: sharedObject.sharedObjectDataJSON,
          };
        }),
      }));
    },
    reactToCommunitySharedObject: (state, action: PayloadAction<{ communityId: string; sharedObjectId: string; reactionType: string }>) => {
      const { communityId, sharedObjectId, reactionType } = action.payload;
      const community = state.communitiesFull.find((community) => community.id === communityId);
      if (!community) {
        return;
      }
      const sharedObjectIndex = community.sharedObjects.findIndex((sharedObject) => sharedObject.id === sharedObjectId);
      if (sharedObjectIndex === -1) {
        return;
      }
      const sharedObject = community.sharedObjects[sharedObjectIndex];
      const reactionSummary = sharedObject.reactionSummary.find((reaction) => reaction.type === reactionType);
      if (reactionSummary) {
        reactionSummary.count++;
      } else {
        sharedObject.reactionSummary.push({ type: reactionType, count: 1 });
      }
      const hasReaction = sharedObject.selfReactions.find((reaction: { type: string }) => reaction.type === reactionType);
      if (!hasReaction) {
        sharedObject.selfReactions.push({ type: reactionType });
      }
    },
    unreactToCommunitySharedObject: (state, action: PayloadAction<{ communityId: string; sharedObjectId: string; reactionType: string }>) => {
      const { communityId, sharedObjectId, reactionType } = action.payload;
      const community = state.communitiesFull.find((community) => community.id === communityId);
      if (community) {
        const sharedObject = community.sharedObjects.find((sharedObject) => sharedObject.id === sharedObjectId);
        if (!sharedObject) {
          return;
        }
        const reactionSummary = sharedObject.reactionSummary.find((reaction) => reaction.type === reactionType);
        if (reactionSummary) {
          reactionSummary.count--;
        }
        const hasReaction = sharedObject.selfReactions.find((reaction: { type: string }) => reaction.type === reactionType);
        if (hasReaction) {
          sharedObject.selfReactions = sharedObject.selfReactions.filter((reaction: { type: string }) => reaction.type !== reactionType);
        }
      }
    },
  },
});

export const {
  clearUserCommunitiesState,
  initializeUserCommunitiesState,
  setCommunitiesErrorMessage,
  initializeUserCommunitiesFullState,
  reactToCommunitySharedObject,
  unreactToCommunitySharedObject,
} = userCommunitiesSlice.actions;

export const selectUserCommunitiesState = (state: RootState) => state.userCommunities as UserCommunitiesState;

export default userCommunitiesSlice.reducer;
